'use strict'

function protoPngSplitCommand(data) {
  var pieces = []

  for (var idx = 0; idx < data.length; ) {
    if (data[idx] === '"') {
      var endQuoteIdx = data.indexOf('"', idx + 1)
      while (endQuoteIdx !== -1) {
        if (data[endQuoteIdx - 1] === '\\') {
          endQuoteIdx = data.indexOf('"', endQuoteIdx + 1)
          continue
        }
        break
      }

      if (endQuoteIdx === -1) {
        throw new Error('unexpected end of input - missing final quote')
      } else if (
        endQuoteIdx + 1 !== data.length &&
        data[endQuoteIdx + 1] !== ' '
      ) {
        throw new Error('unexpected end of input - expected space')
      }

      const piece = data.substr(idx, endQuoteIdx - idx + 1)
      pieces.push(piece)
      idx = endQuoteIdx + 1 + 1
    } else {
      var endSpaceIdx = data.indexOf(' ', idx)

      if (endSpaceIdx === -1) {
        endSpaceIdx = data.length
      }

      const piece = data.substr(idx, endSpaceIdx - idx)
      pieces.push(piece)
      idx = endSpaceIdx + 1
    }

    if (idx === data.length) {
      pieces.push('')
    }
  }

  const jsonPieces = pieces.map((v) => (v != '' ? JSON.parse(v) : null))

  return jsonPieces
}
exports.splitCommand = protoPngSplitCommand

function protoPngJoinCommand(data) {
  var strPieces = data.map((v) => (v !== null ? JSON.stringify(v) : ''))
  return strPieces.join(' ')
}
exports.joinCommand = protoPngJoinCommand

function protoPngSplitResponse(data) {
  if (data.substr(0, 2) !== 'd=') {
    throw new Error('unrecognized play-n-go message')
  }

  return data.substr(2).split(/\r?\n/g)
}
exports.splitResponse = protoPngSplitResponse

function protoPngJoinResponse(cmds) {
  return 'd=' + cmds.join('\r\n')
}
exports.joinResponse = protoPngJoinResponse
