'use strict'

function xhrHookOpenAny(procFn) {
  ;(function (_base_open) {
    XMLHttpRequest.prototype.open = function () {
      const xhr = this

      const _args = Array.prototype.slice.call(arguments)
      const method = _args.shift()
      const url = _args.shift()

      const reqIn = {
        method: method,
        url: url,
      }

      const reqOut = procFn(xhr, reqIn)

      const reqFinal = {
        ...reqIn,
        ...reqOut,
      }

      const finalArgs = [reqFinal.method, reqFinal.url, ..._args]
      _base_open.apply(xhr, finalArgs)
    }
  })(XMLHttpRequest.prototype.open)
}
exports.hookOpenAny = xhrHookOpenAny

function xhrHookResponse(xhr, procFn) {
  var _base_onreadystatechange = xhr.onreadystatechange

  xhr.onreadystatechange = function () {
    try {
      if (xhr.readyState === 4) {
        const respIn = {
          status: xhr.status,
          data: xhr.responseText,
        }

        const respOut = procFn(xhr, respIn)

        const respFinal = {
          ...respIn,
          ...respOut,
        }

        if (respFinal.status !== respIn.status) {
          Object.defineProperty(xhr, 'status', { value: respFinal.status })
        }

        if (respFinal.data !== respIn.data) {
          Object.defineProperty(xhr, 'responseText', { value: respFinal.data })

          try {
            if (xhr.responseType === '' || xhr.responseType === 'text') {
              Object.defineProperty(xhr, 'response', { value: respFinal.data })
            } else if (xhr.responseType === 'json') {
              const respJson = JSON.parse(respFinal.data)
              Object.defineProperty(xhr, 'response', { value: respJson })
            } else {
              // When we can't generate this type, throw an error and put it as text.
              console.error(
                'xhr hook could not produce the request response type:',
                xhr.responseType
              )
              Object.defineProperty(xhr, 'response', { value: respFinal.data })
            }
          } catch (e) {
            console.error('xhr hook failed to generate response object', e)
          }
        }
      }
    } catch (e) {
      console.error('xhr response hook threw an error:', e)
    }

    if (_base_onreadystatechange) {
      _base_onreadystatechange.apply(xhr, arguments)
    }
  }

  Object.defineProperty(xhr, 'onreadystatechange', {
    get: function () {
      return _base_onreadystatechange
    },
    set: function (value) {
      _base_onreadystatechange = value
    },
  })
}
exports.hookResponse = xhrHookResponse
