'use strict'

function fixedEncodeURIComponent(str) {
  return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
    return '%' + c.charCodeAt(0).toString(16)
  })
}

function qsRewrite(qstr, key, valueFn) {
  var queryParts = qstr.split('&')

  var queryComps = {}
  queryParts.forEach((v) => {
    if (v === '') {
      return
    }

    var kvParts = v.split('=')
    var kvName = decodeURIComponent(kvParts[0])
    var kvValue = undefined
    if (kvParts.length > 1) {
      kvValue = decodeURIComponent(kvParts.slice(1).join('='))
    }

    if (queryComps[kvName]) {
      queryComps[kvName].push(kvValue)
    } else {
      queryComps[kvName] = [kvValue]
    }
  })

  var myPart = queryComps[key]
  var newValue = undefined
  if (myPart) {
    if (myPart.length === 1) {
      newValue = valueFn(myPart[0])
    } else {
      newValue = valueFn(myPart)
    }
  } else {
    newValue = valueFn(undefined)
  }

  if (Array.isArray(newValue)) {
    queryComps[key] = newValue
  } else {
    queryComps[key] = [newValue]
  }

  var newQueryParts = []
  Object.keys(queryComps).map((key) => {
    queryComps[key].forEach((value) => {
      newQueryParts.push(
        fixedEncodeURIComponent(key) + '=' + fixedEncodeURIComponent(value)
      )
    })
  })

  if (newQueryParts.length === 0) {
    return basePath
  }

  return newQueryParts.join('&')
}
exports.rewrite = qsRewrite

function qsRewriteUrl(url, key, value) {
  const queryPos = url.indexOf('?')
  if (queryPos === -1) {
    return url + '?' + qsRewrite('', key, value)
  }

  const basePath = url.substr(0, queryPos)
  const queryStr = url.substr(queryPos + 1)

  const newQueryStr = qsRewrite(queryStr, key, value)
  if (newQueryStr === '') {
    return basePath
  }

  return basePath + '?' + newQueryStr
}
exports.rewriteUrl = qsRewriteUrl
