'use strict'

const qsutils = require('./qsutils')
const xhrutils = require('./xhrutils')
const protopng = require('./protos/playngo')

const pageUrlStr = window.location.toString()

function rewriteLocation(procFn) {
  var newLoc = procFn(pageUrlStr)
  if (newLoc !== pageUrlStr) {
    window.location.replace(newLoc)
  }
}

// NyxOP Launcher (Thunderkick)
// Seen on playzee
if (~pageUrlStr.indexOf('nyxop.net')) {
  rewriteLocation((loc) => {
    loc = qsutils.rewriteUrl(loc, 'jurisdiction', () => 'mt')
    return loc
  })
}

// White Hat
// Seen on playzee
if (~pageUrlStr.indexOf('d1k6j4zyghhevb.cloudfront.net')) {
  rewriteLocation((loc) => {
    loc = qsutils.rewriteUrl(loc, 'jurisdiction', () => 'MT')
    return loc
  })
}

// Thunderkick
// Seen on videoslots
if (~pageUrlStr.indexOf('thunderkick.com')) {
  rewriteLocation((loc) => {
    loc = qsutils.rewriteUrl(loc, 'regulator', () => 'MT')
    return loc
  })
}

// No Limit
if (~pageUrlStr.indexOf('nolimitcdn.com')) {
  rewriteLocation((loc) => {
    const nlJuris = JSON.stringify({ name: 'MT' })
    loc = qsutils.rewriteUrl(loc, 'jurisdiction', () => nlJuris)
    return loc
  })
}

// RelaxG & PUSH Gaming
if (~pageUrlStr.indexOf('relaxg.com')) {
  rewriteLocation((loc) => {
    loc = qsutils.rewriteUrl(loc, 'jurisdiction', () => 'MT')
    return loc
  })
}

// Pragmatic
if (~pageUrlStr.indexOf('pragmaticplay.net')) {
  rewriteLocation((loc) => {
    loc = qsutils.rewriteUrl(loc, 'jurisdictionID', () => 'MT')
    return loc
  })
}

// Blueprint
if (~pageUrlStr.indexOf('blueprintgaming.com')) {
  rewriteLocation((loc) => {
    loc = qsutils.rewriteUrl(loc, 'configUrl', (url) => {
      return qsutils.rewriteUrl(url, 'bp_jurisdiction', () => 'MT')
    })
  })
}

// Red Tiger
if (~pageUrlStr.indexOf('redtiger.cash')) {
  xhrutils.hookOpenAny((xhr, req) => {
    if (~req.url.indexOf('/platform/game/settings')) {
      xhrutils.hookResponse(xhr, (_, resp) => {
        var dataJson = JSON.parse(resp.data)

        dataJson.result.user.country = 'MT'

        return {
          data: JSON.stringify(dataJson),
        }
      })
    }
  })
}

// NetEnt
if (~pageUrlStr.indexOf('casinomodule.com')) {
  xhrutils.hookOpenAny((xhr, req) => {
    if (~req.url.indexOf('CasinoGameServlet')) {
      xhrutils.hookResponse(xhr, (_, resp) => {
        var newData = resp.data

        newData = qsutils.rewrite(newData, 'g4mode', () => 'false')
        newData = qsutils.rewrite(newData, 'jurisdictionCode', () => 'MT')
        newData = qsutils.rewrite(
          newData,
          'config.gameParameters.quickSpinAvailable',
          () => 'true'
        )
        newData = qsutils.rewrite(
          newData,
          'config.gameParameters.quickStopAvailable',
          () => 'true'
        )

        return {
          data: newData,
        }
      })
    }
  })
}

// PlaynGo
if (~pageUrlStr.indexOf('playngonetwork.com')) {
  xhrutils.hookOpenAny((xhr, req) => {
    if (~req.url.indexOf('fly.playngonetwork.com')) {
      xhrutils.hookResponse(xhr, (_, resp) => {
        const cmds = protopng.splitResponse(resp.data)

        const newCmds = cmds
          .map((cmdStr) => protopng.splitCommand(cmdStr))
          .map((cmd) => {
            if (cmd[0] === 101) {
              cmd[4] = 'MT'
            }
            return cmd
          })
          .map((cmd) => protopng.joinCommand(cmd))

        return {
          data: protopng.joinResponse(newCmds),
        }
      })
    }
  })
}
